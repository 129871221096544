import React from "react";
import { FileCard } from "components/RFQ/FileCard";

const DocumentationComp = ({ data, documentFilter }) => {
	const documentlist = documentFilter
		? data?.filter((item) => item.categoryId === documentFilter)
		: data;

	const documents = documentlist.reduce((result, document) => {
		const fileData = {
			name: document?.fileName,
			file_size: document?.fileSize,
			path: document?.documentPath,
		};
		// Check if the category key exists, if not initialize it as an array
		if (!result[document.categoryName]) {
			result[document.categoryName] = [];
		}
		// Push the name into the corresponding category array
		result[document.categoryName].push(fileData);
		return result;
	}, {});

	const categories = Object.keys(documents);

	return (
		<>
			{categories?.map((category) => {
				return (
					<div className="pb-6">
						<h1 className="text-lg font-bold">{category}</h1>
						{documents[category]?.map((document, index) => {
							return (
								<FileCard
									key={index}
									file={document}
									isPreview
								/>
							);
						})}
					</div>
				);
			})}
		</>
	);
};
export default DocumentationComp;
