import React from "react";
import tw, { styled, css } from "twin.macro";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "components/RFQ/Table";
import { Button } from "components/RFQ/Button";
import { Flex } from "@chakra-ui/react";
import { Icon } from "assets/icons/Icon";
import { cn } from "utils/helpers";
import { Checkbox } from "components/RFQ/Checkbox";
import moment from "moment/moment";
import { Avatar } from "components/RFQ/Avatar";
import * as constant from "utils/constants";
import { SubmissionEditButton } from "components/Pages/RFQDetail/SubmissionEditButton";
import StatusBadge from "components/RFQ/StatusBadge/StatusBadge";
import { navigate } from "@reach/router";
import { useSelector } from "react-redux";
import Tooltip from "components/Tooltip";
import { downloadRFqSubmissionPDF } from "actions/RFQ/rfqsAction";
const ListViewStyled = styled.div(() => [
	tw`my-8 rounded-2xl`,
	css`
		box-shadow: 0px 0px 10px -4px rgba(0, 0, 0, 0.25);
		.title {
			font-weight: 500;
			font-family: Roboto;
			font-size: 14px;
		}

		.text {
			font-weight: 400;
			font-family: Roboto;
			font-size: 14px;
		}
	`,
]);

const SkeletonCustom = styled.div(() => [
	tw`animate-pulse bg-gray-300 w-full h-6`,
]);

const SkeleteonRow = () => (
	<TableRow>
		<TableCell>
			<SkeletonCustom />
		</TableCell>
		<TableCell>
			<SkeletonCustom />
		</TableCell>
		<TableCell>
			<SkeletonCustom />
		</TableCell>
		<TableCell>
			<SkeletonCustom />
		</TableCell>
		<TableCell>
			<SkeletonCustom />
		</TableCell>
		<TableCell>
			<SkeletonCustom />
		</TableCell>
	</TableRow>
);

const RFQListSubmission = ({
	checkedRow,
	setCheckedRow,
	totalCompany,
	currPage,
	loadList,
	onOpen,
	setTypeModal,
	rfqDetail,
	isLoading,
	currentTab,
	typeGuestName,
	isSubmitted,
	navigateCompareSelected,
}) => {
	const { accepted, all, declined, submitted } = rfqDetail?.supplierInvited || {
		accepted: [],
		all: [],
		declined: [],
		submitted: [],
	};
	const rowSelected = (rowId) =>
		checkedRow?.some((row) => row?.company.id === rowId);
	const companyId = useSelector((state) => state.auth.user?.company_id) || null;
	const TableHeadComponent = ({ title, className }) => {
		return (
			<div
				className={cn("th font-roboto text-tertiary-600", className)}
				style={{ gap: "8px", alignItems: "center" }}
			>
				{title}
			</div>
		);
	};
	const companies = () => {
		switch (currentTab) {
			case constant.ALL_INVITED_SUPPLIER:
				return all;
			case constant.ACCEPTED:
				return [...accepted];
			case constant.DECLINED:
				return declined;
			case constant.SUBMITTED:
				return submitted;
			default:
				return all;
		}
	};

	const checkHandler = (e, row) => {
		if (e.target.checked) {
			setCheckedRow((prevRow) => {
				let newArray = [...prevRow, row].sort((a, b) => a.id - b.id);
				return newArray;
			});
		} else {
			const newArray = checkedRow.filter((checked) => checked.id !== row.id);
			setCheckedRow(newArray);
		}
	};

	const checkNoNull = (text) => {
		if (text) {
			text = text.charAt(0).toUpperCase() + text.slice(1);
			return text + ", ";
		} else {
			return "";
		}
	};

	const dateDiffChecker = (date) => {
		const today = moment(new Date());
		const lastUpdated = moment(date);
		const diffTime = today.diff(lastUpdated, "days");
		if (diffTime <= 1) {
			return moment(date).fromNow();
		} else {
			return moment(date).format("MMM DD YYYY");
		}
	};

	const viewSubmissionHandler = (data) => {
		if (data?.company) {
			navigate(`../../${data?.rfqId}/submission/${data?.company.id}`);
		} else {
			navigate(`../../${data?.rfqId}/guest/submission/${data?.guest_user_id}`);
		}
		setTypeModal("submission");
		onOpen();
	};

	const downloadSubmission = async (data) => {
		if (data?.company) {
			downloadRFqSubmissionPDF(data?.rfqId, data?.company.id).then((res) => {
				window.open(res?.data?.file_path, "_blank");
				return res;
			});
		} else {
			downloadRFqSubmissionPDF(data?.rfqId, data?.guest_user_id).then((res) => {
				window.open(res?.data?.file_path, "_blank");
				return res;
			});
		}
		setTypeModal("submission");
		onOpen();
	};

	const inviteNewSupplier = () => {
		let idSupplierList = rfqDetail?.supplierList?.id;
		let supplierListName = rfqDetail?.supplierList?.name;
		navigate(
			`/account/${companyId}/supplier-list/${idSupplierList}/${supplierListName}`
		);
	};

	return (
		<ListViewStyled>
			<div className="w-full rounded-t-2xl border-gray-200 p-6">
				{checkedRow?.length === 0 ? (
					<h2 className="min-h-[46px] text-lg font-semibold leading-[46px] text-gray-800">
						All Invited Suppliers
					</h2>
				) : (
					<div className="flex items-center justify-between gap-4">
						<div className="flex flex-col items-baseline gap-1">
							<h2 className="text-lg font-semibold text-primary-900">
								All Invited Suppliers
							</h2>
							<p className="text-sm">
								{checkedRow?.length} Selected of {totalCompany}
							</p>
						</div>
						<div className="flex items-center gap-4">
							<Button
								type="button"
								btntype="base"
								onClick={() => navigateCompareSelected("compare")}
							>
								<Flex
									align={"center"}
									className="gap-2"
								>
									<Icon
										icon="switch-horizontal"
										color="#0031DD"
									/>
									<p className="font-roboto font-semibold">Compare Selected</p>
								</Flex>
							</Button>

							<Button
								type="button"
								btntype="secondary"
								onClick={() => navigateCompareSelected("shortlist")}
							>
								<Flex
									align={"center"}
									className="gap-2"
								>
									<Icon icon="download" />
									<p className="font-roboto font-semibold text-utility-blue-dark-100">
										Shortlist
									</p>
								</Flex>
							</Button>
						</div>
					</div>
				)}
			</div>
			<Table
				containerClass={tw`w-full rounded-2xl rounded-t-none border-t border-gray-200`}
			>
				<TableHeader>
					<TableRow>
						<TableHead
							firstCol={true}
							className="!pl-6"
						>
							<div
								className="flex"
								style={{ gap: "8px", alignItems: "center" }}
							>
								<TableHeadComponent title="Company"></TableHeadComponent>
							</div>
						</TableHead>
						<TableHead>
							<TableHeadComponent title="Company Size"></TableHeadComponent>
						</TableHead>
						<TableHead>
							<TableHeadComponent title="Location"></TableHeadComponent>
						</TableHead>
						<TableHead>
							<TableHeadComponent title="Last Updated"></TableHeadComponent>
						</TableHead>
						<TableHead>
							<TableHeadComponent title="Status"></TableHeadComponent>
						</TableHead>
						<TableHead>
							<TableHeadComponent
								title="Button View"
								className="hidden"
							/>
						</TableHead>
					</TableRow>
				</TableHeader>
				<TableBody>
					{(currPage <= 2 && isLoading) || loadList
						? [...Array(4)].map(() => <SkeleteonRow />)
						: companies()?.map((company, index) => {
								return (
									<TableRow
										key={index}
										className={`${cn(
											rowSelected(company?.company?.id) &&
												"[&>td]:bg-utility-blue-light-50/30"
										)} group`}
									>
										<TableCell
											firstCol={true}
											className="w-[35%] overflow-hidden !pl-6 group-hover:bg-utility-blue-light-50/30"
										>
											<div className="flex gap-4">
												<Checkbox
													disabled={company?.company ? false : true}
													onChange={(e) => checkHandler(e, company)}
													checked={rowSelected(company?.company?.id)}
													className="cursor-pointer"
												/>
												<div className="relative ml-3 flex items-center gap-2 text-black">
													<Avatar
														avatar={company?.company?.logo_path}
														size="lg"
														className="bg-white shadow-md"
													/>
													<p className="title">
														{company.company
															? company?.company?.name
															: company.name
																? company.name
																: " - "}
													</p>
												</div>
											</div>
										</TableCell>
										<TableCell className="group-hover:bg-utility-blue-light-50/30">
											<p className="text">
												{company?.company?.size_name || " - "}
											</p>
										</TableCell>
										<TableCell className="group-hover:bg-utility-blue-light-50/30">
											<p className="text">
												{company?.company?.locations?.length > 0 &&
												company?.company?.locations[0]
													? `${checkNoNull(company?.company?.locations?.[0]?.city)} ${company?.company?.locations?.[0]?.state}`
													: " - "}
											</p>
										</TableCell>
										<TableCell className="group-hover:bg-utility-blue-light-50/30">
											{company?.lastUpdated
												? dateDiffChecker(company?.lastUpdated)
												: "-"}
										</TableCell>
										<TableCell className="group-hover:bg-utility-blue-light-50/30">
											<StatusBadge
												status={company?.status}
												submittedDateTime={company?.submittedAt}
											/>
										</TableCell>
										<TableCell className="w-1/5 group-hover:bg-utility-blue-light-50/30">
											<div className="grid grid-cols-5">
												{isSubmitted(company?.status) && (
													<div className="col-span-4">
														<Button
															btntype="secondary"
															className={"!p-2"}
															onClick={() => viewSubmissionHandler(company)}
														>
															<span className="text-[12px]">View Response</span>
														</Button>
														<Button
															btntype="secondary"
															className={"!p-2"}
															onClick={() => downloadSubmission(company)}
														>
															<span className="text-[12px]">
																Download Response
															</span>
														</Button>
													</div>
												)}
												<div>
													{isSubmitted(company?.status) ? (
														<SubmissionEditButton
															isSubmitted={isSubmitted(company?.status)}
															guestname={
																company.company
																	? company?.company?.name
																	: company.name
																		? company.name
																		: " - "
															}
															companystatus={company?.status}
															setTypeModal={setTypeModal}
															onOpen={onOpen}
															companyId={company?.company?.id}
															viewSubmission={() =>
																viewSubmissionHandler(company)
															}
															typeGuestName={typeGuestName}
															email={company?.email}
														/>
													) : null}
												</div>
											</div>
										</TableCell>
									</TableRow>
								);
							})}
					{isLoading &&
						currPage > 2 &&
						[...Array(2)].map(() => <SkeleteonRow />)}
					<TableRow>
						<TableCell colSpan={5}>
							<Tooltip
								label="Browse the marketplace to add suppliers to your Supplier list"
								placement="top-start"
								hasArrow
							>
								<Button
									btntype="secondary"
									className="!text-xs"
									onClick={inviteNewSupplier}
								>
									<Flex
										align={"center"}
										gap={1}
									>
										<Icon
											icon="plus-square"
											color="#0031DD"
										/>
										<div>Invite Supplier</div>
									</Flex>
								</Button>
							</Tooltip>
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</ListViewStyled>
	);
};

export default RFQListSubmission;
