import { Card, Flex, SkeletonCircle } from "@chakra-ui/react";
import { Icon } from "assets/icons/Icon";

import { Button } from "components/RFQ/Button";
import { SkeletonBase } from "components/Skeleton";
import { SubmissionSmartFieldPreviewContext } from "context/RFQSubmittedResponseContext";
import { useContext } from "react";
import { basePathAuth, cn, filenameSpliter } from "utils/helpers";

export const ProjectCardSkeleton = ({ rfqSubmission }) => {
	return (
		<div
			className="mb-3 mr-5 mt-3 animate-pulse overflow-hidden rounded-md bg-gray-100 pb-6"
			style={{ minWidth: "401px" }}
		>
			<SkeletonBase
				width="100%"
				height="145px"
			/>
			<div className="space-y-6 px-6 py-2">
				<div className="mb-3 mt-10 flex items-center justify-start gap-3">
					<SkeletonBase width="160px" />
				</div>
				<div className="space-y-4 pb-2 pt-3">
					<SkeletonBase width="100%" />
					<SkeletonBase width="100%" />
					<SkeletonBase width="100%" />
					<SkeletonBase width="100%" />
				</div>
				{rfqSubmission && (
					<SkeletonBase
						width="100%"
						height="46px"
					/>
				)}
			</div>
		</div>
	);
};

export const ProjectCard = ({
	values,
	rfqSubmission,
	selected,
	selectHandler,
	companyId,
	isPreview,
}) => {
	const submissionSmartFieldContext = useContext(
		SubmissionSmartFieldPreviewContext
	);
	function formatCurrency(value) {
		if (value >= 1e6) {
			return (value / 1e6).toFixed(1) + "M";
		} else if (value >= 1e3) {
			return (value / 1e3).toFixed(1) + "K";
		} else if (value > 0) {
			return value.toFixed(1);
		} else {
			return " - ";
		}
	}

	return (
		<div
			className="mb-3 mr-5 mt-3 h-max overflow-hidden rounded-md bg-white pb-3 shadow-md"
			style={{ minWidth: "401px" }}
		>
			<div
				className="relative flex items-center justify-between"
				style={{ height: "145px" }}
			>
				<div className="absolute left-0 top-0 h-[6px] w-full bg-royal-blue2"></div>
				<div
					className="bg-cover"
					style={{
						width: "100%",
						height: "145px",
						backgroundImage: `url(${values?.images?.length > 0 && values?.images[0]})`,
					}}
				></div>
			</div>
			<div className="space-y-6 pb-3 pl-5 pr-5 pt-3">
				<div>
					<h3 className="mt-8 line-clamp-1 overflow-hidden font-roboto text-[20px] font-semibold capitalize text-primary-900">
						{values?.name || " - "}
					</h3>
				</div>
				<div>
					<div
						className="flex items-center justify-between space-x-0 border-b border-solid border-[#EAECF0]"
						style={{ height: "40px" }}
					>
						<div className="font-roboto text-[14px] font-normal text-primary-500">
							Construction Value
						</div>
						<div className="font-roboto text-[14px] font-semibold text-primary-900">
							${formatCurrency(values?.value)}
						</div>
					</div>
					<div
						className="flex items-center justify-between space-x-0 border-b border-solid border-[#EAECF0]"
						style={{ height: "40px" }}
					>
						<div className="font-roboto text-[14px] font-normal text-primary-500">
							Completion
						</div>
						<div className="font-roboto text-[14px] font-semibold text-primary-900">
							{values?.year_completed || " - "}
						</div>
					</div>
					<div
						className="flex items-center justify-between space-x-0 border-b border-solid border-[#EAECF0]"
						style={{ height: "40px" }}
					>
						<div className="font-roboto text-[14px] font-normal text-primary-500">
							Client Name
						</div>
						<div className="font-roboto text-[14px] font-semibold text-primary-900">
							{values?.client_name || " - "}
						</div>
					</div>
					<div
						className="flex items-center justify-between space-x-0"
						style={{ height: "40px" }}
					>
						<div className="font-roboto text-[14px] font-normal text-primary-500">
							Procurement Model
						</div>
						<div className="font-roboto text-[14px] font-semibold text-primary-900">
							{values?.procurement_methods[0]?.name || " - "}
						</div>
					</div>
				</div>
				<div className="flex gap-3">
					{rfqSubmission && !isPreview && (
						<>
							<Button
								type="button"
								btntype={selected ? "primary" : "secondary"}
								className="!w-full items-center gap-1.5"
								onClick={() => selectHandler(values?.id)}
							>
								{selected ? "Selected" : "Select"}
								{selected && (
									<Icon
										icon="check-only"
										width={20}
										height={20}
									/>
								)}
							</Button>
							<Button
								as="a"
								btntype="secondary"
								className="!w-full"
								href={`${basePathAuth(companyId)}company-profile/projects/edit-project/${values?.id}`}
								target="_blank"
							>
								Open Project
							</Button>
						</>
					)}

					{isPreview && (
						<Button
							btntype="secondary"
							className="!w-full"
							onClick={() => {
								submissionSmartFieldContext?.setModalDetail({
									data: { ...values },
									type: "project",
								});
								submissionSmartFieldContext?.setIsModalOpen(true);
							}}
						>
							View Project
						</Button>
					)}
				</div>
			</div>
		</div>
	);
};

export const KeyPersonnelSkeleton = ({ rfqSubmission }) => {
	return (
		<div
			className="mb-3 mr-5 mt-3 animate-pulse overflow-hidden rounded-md bg-gray-100 pb-6"
			style={{ minWidth: "401px" }}
		>
			<div className="space-y-6 px-6 py-2">
				<div className="mb-3 mt-5 flex items-center justify-start gap-3">
					<SkeletonCircle
						size="56px"
						startColor="lightgray"
					/>
					<SkeletonBase width="160px" />
				</div>
				<div className="space-y-4 pb-3 pt-3">
					<SkeletonBase width="100%" />
					<SkeletonBase width="100%" />
					<SkeletonBase width="100%" />
				</div>
				{rfqSubmission && (
					<SkeletonBase
						width="100%"
						height="46px"
					/>
				)}
			</div>
		</div>
	);
};

export const KeyPersonnel = ({
	values,
	rfqSubmission,
	selected,
	selectHandler,
	companyId,
	isPreview,
}) => {
	const submissionSmartFieldContext = useContext(
		SubmissionSmartFieldPreviewContext
	);

	return (
		<div
			className="mb-3 mr-5 mt-3 h-max overflow-hidden rounded-md bg-white pb-6 shadow-md"
			style={{ minWidth: "435px" }}
		>
			<div
				className="relative flex items-center justify-between"
				style={{ height: "6px" }}
			>
				<div className="absolute left-0 top-0 h-[6px] w-full bg-royal-blue2"></div>
			</div>
			<div className="space-y-6 px-6">
				<div className="mb-3 mt-5 flex items-center justify-start">
					<div className="">
						<div
							className="rounded-full"
							style={{
								width: "56px",
								height: "56px",
								backgroundImage: `url(${values?.avatar_location || "/img/user-01.svg"})`,
								backgroundSize: "cover",
							}}
						></div>
					</div>
					<div
						className="ml-4 font-roboto font-semibold capitalize text-tertiary-900"
						style={{ fontSize: "20px", lineHeight: "30px" }}
					>
						{(values?.first_name || "") + "  " + (values?.last_name || "")}
					</div>
				</div>
				<div className="pb-3 pt-1">
					<div
						className="flex items-center justify-between space-x-0 border-b border-solid border-[#EAECF0]"
						style={{ height: "40px" }}
					>
						<div className="font-roboto text-[14px] font-normal text-primary-500">
							Position
						</div>
						<div className="font-roboto text-[14px] font-semibold text-primary-900">
							{values?.position || " - "}
						</div>
					</div>
					<div
						className="flex items-center justify-between space-x-0 border-b border-solid border-[#EAECF0]"
						style={{ height: "40px" }}
					>
						<div className="font-roboto text-[14px] font-normal text-primary-500">
							Industry experience
						</div>
						<div className="font-roboto text-[14px] font-semibold text-primary-900">
							{values?.experience || 0} years
						</div>
					</div>
					<div
						className="flex items-center justify-between space-x-0"
						style={{ height: "40px" }}
					>
						<div className="font-roboto text-[14px] font-normal text-primary-500">
							Most Recent Project
						</div>
						<div className="line-clamp-1 max-w-[50%] font-roboto text-[14px] font-semibold text-primary-900">
							{values?.projects_completed.length > 0
								? values?.projects_completed[0]?.name
								: " - "}
						</div>
					</div>
				</div>
				<div className="flex gap-3">
					{rfqSubmission && !isPreview && (
						<>
							<Button
								type="button"
								btntype={selected ? "primary" : "secondary"}
								className="!w-full items-center gap-1.5"
								onClick={() => selectHandler(values?.id)}
							>
								{selected ? "Selected" : "Select"}
								{selected && (
									<Icon
										icon="check-only"
										width={20}
										height={20}
									/>
								)}
							</Button>
							<Button
								as="a"
								btntype="secondary"
								className="!w-full"
								href={`${basePathAuth(companyId)}company-profile/resources/edit-resource/${values?.id}`}
								target="_blank"
							>
								Open Key Personnel
							</Button>
						</>
					)}

					{isPreview && (
						<Button
							btntype="secondary"
							className="!w-full"
							onClick={() => {
								submissionSmartFieldContext.setModalDetail({
									data: values,
									type: "resource",
								});
								submissionSmartFieldContext.setIsModalOpen(true);
							}}
						>
							View Key Personnel
						</Button>
					)}
				</div>
			</div>
		</div>
	);
};

export const CorporateDocument = ({
	values,
	isPreview,
	selected,
	selectHandler,
	isLoading,
	companyId,
}) => {
	const DocumentCard = ({ file, isPreview, selected, selectHandler }) => {
		return (
			<Card
				className={cn(
					`border-gray-outline hover:text-purple group my-4 cursor-pointer border px-4 py-3 hover:border-utility-brand-100 hover:bg-utility-brand-100`
				)}
				radius="sm"
				onClick={() => (selectHandler ? selectHandler(file.id) : null)}
			>
				<Flex className="relative items-center justify-between gap-2">
					<Flex className="relative items-center justify-between gap-4">
						<Icon
							icon={file.ext}
							size="md"
						/>
						<div className="relative">
							<p className="line-clamp-1 max-w-[240px] text-sm font-medium">
								{file.name}
							</p>
							<p className="line-clamp-1 max-w-[240px] text-sm">
								{file.categoryName}
							</p>
						</div>
					</Flex>
					<Flex className="gap-2">
						{!isPreview && (
							<div
								className={`${selected ? "flex" : "hidden"} cursor-pointer group-hover:flex`}
							>
								<Icon icon="check" />
							</div>
						)}
						{file?.storage_path && isPreview && (
							<a
								href={file?.storage_path || ""}
								target="_blank"
								rel="noreferrer"
								download={file.name ? "download" : null}
								className={cn(
									"hidden rounded-full bg-gray-200 p-2 hover:bg-red-200 group-hover:flex",
									isPreview && "hover:bg-utility-brand-200"
								)}
							>
								<Icon
									icon={isPreview ? "download" : "trash"}
									style={{
										width: "18px",
										height: "18px",
										cursor: "pointer",
										color: "black",
									}}
								/>
							</a>
						)}
						{!isPreview && (
							<a
								href={`${basePathAuth(companyId)}company-profile/documents`}
								target="_blank"
								rel="noreferrer"
								className={cn(
									"-mr-8 rounded-full bg-gray-200 p-2 opacity-0 transition-all hover:bg-utility-brand-200 group-hover:mr-0 group-hover:flex group-hover:opacity-100",
									isPreview && "hover:bg-utility-brand-200"
								)}
							>
								<Icon
									icon={"arrow-right"}
									style={{
										width: "18px",
										height: "18px",
										cursor: "pointer",
										color: "black",
									}}
								/>
							</a>
						)}
					</Flex>
				</Flex>
			</Card>
		);
	};

	const DocumentCardSkeleton = () => {
		return (
			<div className="flex h-16 w-full animate-pulse items-center gap-3 rounded-lg bg-gray-100 px-6">
				<SkeletonBase
					width="36px"
					height="36px"
				/>
				<div className="space-y-2">
					<SkeletonBase
						height="16px"
						width="240px"
					/>
					<SkeletonBase
						height="16px"
						width="180px"
					/>
				</div>
			</div>
		);
	};

	const documents = values.reduce((result, document) => {
		const { filename, ext } = filenameSpliter(document?.file_name);
		const file = {
			name: filename || " - ",
			categoryName: document?.category?.name || " -",
			ext: ext,
			checked: false,
			storage_path: document?.storage_path || null,
			id: document?.id,
		};
		// Check if the category key exists, if not initialize it as an array
		if (!result[document?.category?.name]) {
			result[document?.category?.name] = [];
		}
		// Push the name into the corresponding category array
		result[document?.category?.name].push(file);
		return result;
	}, {});

	const valuesLength = values?.length || 0;
	return (
		<div>
			{isLoading
				? [...Array(3)].map((_, index) => <DocumentCardSkeleton key={index} />)
				: Array.isArray(values) && values.length > 0
					? Object.keys(documents)?.map((category) => {
							return (
								<div>
									<h1 className="text-lg font-bold">{category}</h1>
									{documents[category]?.map((document) => (
										<div
											className={cn(
												"grid w-full gap-x-3 gap-y-0",
												valuesLength > 9
													? "grid-flow-col grid-rows-3"
													: "grid-cols-3"
											)}
										>
											<DocumentCard
												file={document}
												isPreview={isPreview}
												selectHandler={selectHandler}
												selected={selected ? selected(document?.id) : null}
											/>
										</div>
									))}
								</div>
							);
						})
					: " - "}
		</div>
	);
};
